import { memo } from "react";
import { Box, Dialog } from "@mui/material";
import { MediaDetailsDialogProps } from "./props.type";
import Text from "@/components/Text";
import { CustomInputField, Image, Video } from "./styles";
import Button from "@/components/Button";
import Spacer from "@/components/Spacer";
import { useMediaDetailsDialog } from "./useMediaDetailsDialog";

const MediaDetailsDialog = (props: MediaDetailsDialogProps) => {
  const {
    isOpen,
    variant = "photo",
    mediaUrl,
    customInputs,
    disableCustomInputs,
    feedback,
  } = props;

  const {
    hasCustomInputs,
    taskInputsAux,

    errors,
    isValidInputs,

    setKeyValue,
    onClickCancel,
    onClickSave,
  } = useMediaDetailsDialog(props);

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          borderRadius: "2rem",
        },
      }}
    >
      <Box p="1.5rem" display="flex" flexDirection="column" alignItems="center">
        <Text fontSize="1rem" mb="1.5rem">
          DETALHES {variant === "video" ? "DO VÍDEO" : "DA IMAGEM"}
        </Text>

        {variant === "video" ? (
          <Video src={mediaUrl} controls />
        ) : (
          <Image src={mediaUrl} />
        )}

        <Box width="100%">
          {feedback ? (
            <>
              <CustomInputField
                variant="description"
                label={"Feedback de reprovação"}
                disabled
                value={feedback}
              />
              <Spacer height="1rem" />
            </>
          ) : (
            <>
              {customInputs?.map(
                ({ key, label, placeholder, variant, maxLength }) => (
                  <>
                    <CustomInputField
                      variant={variant}
                      key={`custom_input-${key}`}
                      disabled={disableCustomInputs}
                      label={label ?? placeholder}
                      value={taskInputsAux?.[key]}
                      setValue={(val) => setKeyValue?.(key, val)}
                      error={!!errors[key]}
                      inputProps={{ maxLength }}
                      helperText={errors[key]}
                    />
                    <Spacer height="1rem" />
                  </>
                )
              )}
            </>
          )}

          <Box
            display="flex"
            flexDirection={hasCustomInputs ? "row" : "row-reverse"}
          >
            <Button
              variant="outlined"
              style={{
                padding: "0.4rem 2.5rem",
                alignSelf: "end",
                width: "fit-content",
              }}
              onClick={onClickCancel}
            >
              {hasCustomInputs ? "Cancelar" : "Sair"}
            </Button>

            <Spacer grow />
            {hasCustomInputs && (
              <Button
                disabled={disableCustomInputs || !isValidInputs}
                style={{
                  padding: "0.4rem 2.5rem",
                  width: "fit-content",
                }}
                onClick={onClickSave}
              >
                Salvar
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default memo(MediaDetailsDialog);
