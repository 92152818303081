import {
  deleteTaskSubmission,
  submitMediaChallenge,
} from "@/services/challenges/mediaChallenges";
import {
  MediaType,
  TaskChallengeStatus,
} from "@/services/challenges/types/challenges.enums";
import {
  ChallengeTask,
  ChallengeType,
} from "@/services/challenges/types/challenges.types";
import { queryClient } from "@/services/react-query";
import Theme from "@/utils/theme";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// arrumar caso em que a pessoa envia uma task dps do default open index
// verificar se quebrou em outros lugares
export const useMediaChallengeSubmission = () => {
  const navigate = useNavigate();
  const [showHasDeleteDialog, setShowHasDeleteDialog] = useState(false);
  const [accordionKey, setAccordionKey] = useState(0);
  const resetAccordionDefaults = () => setAccordionKey(accordionKey + 1);
  const [accordionIndexToClose, setAccordionIndexToClose] = useState<number>();

  const [challenge, setChallenge] = useState<ChallengeType>();
  const [taskMedias, setTaskMedias] = useState<{ [taskId: number]: string }>(
    {}
  );
  const setTaskMedia = (task: ChallengeTask, mediaUrl: string) => {
    setTaskMedias({ ...taskMedias, [task.id]: mediaUrl });

    if (task.customInputs && task.customInputs.length > 0) {
      showTooltip(task.id, "details-button");
    }
  };

  const removeTaskMedia = async (task: ChallengeTask) => {
    try {
      if (task.status === TaskChallengeStatus.REJECTED) {
        await deleteTaskSubmission(task.id);
        queryClient.invalidateQueries({
          queryKey: "challenge-details",
          exact: false,
        });
      }

      toggleDeleteTaskDialog(task.id);
      setTaskMedias({ ...taskMedias, [task.id]: "" });
      setTaskInputs({ ...taskInputs, [task.id]: {} });
      queryClient.invalidateQueries({
        queryKey: ["stage-challenges", challenge?.stageId],
      });
    } catch (err) {
      console.error("Error deleting task", err);
    }
  };

  const [loadingTaskId, setLoadingTaskId] = useState(-1);

  const [taskInputs, setTaskInputs] = useState<{
    [taskId: number]: { [key: string]: string };
  }>({});
  const updateTaskInputValue =
    (taskId: number) => (inputs: { [key: string]: string }) => {
      setTaskInputs({ ...taskInputs, [taskId]: inputs });
    };

  const [openMediaDetailsId, setOpenMediaDetailsId] = useState(-1);
  const toggleMediaDetails = (taskId: number) => {
    if (openMediaDetailsId === -1) {
      return setOpenMediaDetailsId(taskId);
    }

    setOpenMediaDetailsId(-1);
  };

  const [showRequiredInputDialog, setShowRequiredInputDialog] = useState(false);
  const toggleRequiredInputDialog = () =>
    setShowRequiredInputDialog(!showRequiredInputDialog);

  const [deleteTaskId, setDeleteTaskId] = useState(-1);
  const toggleDeleteTaskDialog = (taskId: number) => {
    if (deleteTaskId === -1) {
      return setDeleteTaskId(taskId);
    }

    setDeleteTaskId(-1);
  };

  const [showHasChangesDialog, setShowHasChangesDialog] = useState(false);
  const toggleHasChangesDialog = () =>
    setShowHasChangesDialog(!showHasChangesDialog);
  const toggleHasDeleteDialog = () => setShowHasDeleteDialog((old) => !old);

  const [hideTooltipTaskIds, setHideTooltipTaskIds] = useState<{
    [id: number]: { [key: string]: boolean };
  }>({});

  const hideTooltip = (taskId: number, tooltipIdentifier: string) => {
    setHideTooltipTaskIds({
      ...hideTooltipTaskIds,
      [taskId]: { ...hideTooltipTaskIds[taskId], [tooltipIdentifier]: false },
    });
  };
  const showTooltip = (taskId: number, tooltipIdentifier: string) => {
    setHideTooltipTaskIds({
      ...hideTooltipTaskIds,
      [taskId]: { ...hideTooltipTaskIds[taskId], [tooltipIdentifier]: true },
    });
  };

  const getShowTooltip = (taskId: number, tooltipIdentifier: string) => {
    return !!hideTooltipTaskIds[taskId]?.[tooltipIdentifier];
  };

  const getTaskStatus = (task: ChallengeTask) => {
    if (task.status === TaskChallengeStatus.APPROVED) {
      return {
        color: Theme.colors.feedback.positive,
        text: "Aprovado",
      };
    }

    if (task.status === TaskChallengeStatus.FINISHED) {
      return {
        color: Theme.colors.feedback.positive,
        text: "Concluído",
      };
    }

    if (task.status === TaskChallengeStatus.SENT) {
      return {
        color: Theme.colors.feedback.info,
        text: "Enviado",
      };
    }

    if (task.status === TaskChallengeStatus.REJECTED) {
      return {
        color: Theme.colors.feedback.negative,
        text: "Reprovado",
      };
    }

    return {
      color: Theme.colors.feedback.warning,
      text: "Envio pendente",
    };
  };

  const getMediaStatus = (task: ChallengeTask) => {
    const currentMediaUrl = taskMedias[task.id];

    if (task.status === TaskChallengeStatus.REJECTED) {
      const text =
        task.mediaType === MediaType.VIDEO
          ? "Vídeo Reprovado"
          : "Foto Reprovada";

      return {
        color: Theme.colors.feedback.negative,
        text,
      };
    }

    if (task.status === TaskChallengeStatus.SENT) {
      return {
        color: Theme.colors.feedback.positive,
        text: "Aguardando Aprovação",
      };
    }

    if (currentMediaUrl === task.submission?.mediaUrl) {
      return {
        color: Theme.colors.feedback.positive,
        text: "Salva",
      };
    }

    return {
      color: Theme.colors.feedback.warning,
      text: "Não salva",
    };
  };

  const getDetailsButton = (
    task: ChallengeTask
  ): { icon: "info" | "chat" | "open"; color: string } => {
    if (task.status === TaskChallengeStatus.REJECTED) {
      return { icon: "info", color: Theme.colors.feedback.warning };
    }

    if (task.customInputs && task.customInputs.length > 0) {
      return { icon: "chat", color: Theme.colors.feedback.info };
    }

    return { icon: "open", color: Theme.colors.icon.default };
  };

  const onSubmitTask = async (taskId: number, mediaUrl: string) => {
    try {
      setLoadingTaskId(taskId);

      if (!validateTaskCustomInputs(taskId)) {
        return toggleRequiredInputDialog();
      }

      await submitMediaChallenge(taskId, mediaUrl, { ...taskInputs[taskId] });
      queryClient.invalidateQueries({
        queryKey: ["stage-challenges", challenge?.stageId],
      });
      queryClient.invalidateQueries({
        queryKey: "challenge-details",
        exact: false,
      });

      resetAccordionDefaults();
      setAccordionIndexToClose(
        challenge?.tasks?.findIndex((i) => i.id === taskId)
      );
      hideTooltip(taskId, "file-input");
    } catch (err) {
      console.error("Error submitting task", err);
      toast("Erro ao enviar desafio", { type: "error" });
    } finally {
      setLoadingTaskId(-1);
    }
  };

  const validateTaskCustomInputs = (taskId: number) => {
    if (!challenge?.tasks) {
      return;
    }

    const task = challenge.tasks.find((t) => t.id === taskId);

    if (task?.customInputs) {
      const taskInputsCount = Object.keys(task.customInputs).length;
      const taskInputValuesCount = Object.keys(taskInputs[taskId] ?? {}).length;

      if (taskInputsCount !== taskInputValuesCount) {
        return false;
      }

      for (const key in taskInputs[taskId]) {
        if (taskInputs[taskId][key].length === 0) {
          return false;
        }
      }
    }

    return true;
  };

  const initialTaskMedias = useMemo(() => {
    if (!challenge?.tasks) return {};

    const initialMedias: { [taskId: number]: string } = {};

    challenge.tasks.forEach((task) => {
      if (task.submission?.mediaUrl)
        initialMedias[task.id] = task.submission?.mediaUrl;
    });

    return initialMedias;
  }, [challenge]);

  const initialHideTooltips = useMemo(() => {
    if (!challenge?.tasks) return {};

    const initial: { [id: number]: { [key: string]: boolean } } = {};

    challenge.tasks.forEach((task) => {
      if (task.status != TaskChallengeStatus.PENDING) return;

      initial[task.id] = { ...initial[task.id] };
      initial[task.id]["file-input"] = true;
    });

    return initial;
  }, [challenge]);

  const initialTaskInputs = useMemo(() => {
    if (!challenge?.tasks) return {};

    const initialInputs: {
      [taskId: number]: { [key: string]: string };
    } = {};

    challenge.tasks.forEach((task) => {
      if (!task.submission?.customInputs) {
        initialInputs[task.id] = { ...initialInputs[task.id] };
        initialInputs[task.id] = taskInputs[task.id];
      } else {
        Object.keys(task.submission.customInputs).forEach((key) => {
          if (!task.submission?.customInputs) return;

          initialInputs[task.id] = { ...initialInputs[task.id] };
          initialInputs[task.id][key] = task.submission.customInputs[key] ?? "";
        });
      }
    });

    return initialInputs;
  }, [challenge]);

  const hasChanges = useMemo(() => {
    return (
      JSON.stringify(initialTaskMedias) !== JSON.stringify(taskMedias) ||
      JSON.stringify(initialTaskInputs) !== JSON.stringify(taskInputs)
    );
  }, [initialTaskMedias, taskMedias, initialTaskInputs, taskInputs]);

  const defaultOpenIndex = useMemo(() => {
    if (!challenge?.tasks?.length) return;

    for (const [index, task] of challenge.tasks.entries()) {
      if (
        task.status === TaskChallengeStatus.PENDING ||
        task.status === TaskChallengeStatus.REJECTED
      ) {
        return index;
      }
    }
  }, [challenge?.tasks, loadingTaskId]);

  const onClickBack = () => {
    if (hasChanges) {
      return toggleHasChangesDialog();
    }

    navigateToChallenges();
  };

  const navigateToChallenges = () => {
    navigate("/challenges");
  };

  const onClickUploadArea = (status: TaskChallengeStatus) => () => {
    if (status === TaskChallengeStatus.REJECTED) {
      toggleHasDeleteDialog();
    }
  };

  useEffect(() => {
    const submittedTasks: any = {};
    Object.keys(initialTaskMedias).forEach((key) => {
      if (initialTaskMedias[+key] !== "") {
        submittedTasks[key] = initialTaskMedias[+key];
      }
    });

    setTaskMedias((prev) => ({ ...prev, ...submittedTasks }));
  }, [initialTaskMedias]);

  useEffect(() => {
    setTaskInputs(initialTaskInputs);
  }, [initialTaskInputs]);

  useEffect(() => {
    setHideTooltipTaskIds(initialHideTooltips);
  }, [initialHideTooltips]);

  return {
    challenge,
    setChallenge,
    getTaskStatus,
    defaultOpenIndex,

    openMediaDetailsId,
    toggleMediaDetails,

    showHasChangesDialog,
    toggleHasChangesDialog,

    accordionKey,
    accordionIndexToClose,
    taskMedias,
    setTaskMedia,
    removeTaskMedia,
    getMediaStatus,
    taskInputs,
    hideTooltip,
    getShowTooltip,
    getDetailsButton,

    showRequiredInputDialog,
    toggleRequiredInputDialog,
    deleteTaskId,
    toggleDeleteTaskDialog,

    updateTaskInputValue,
    onSubmitTask,
    loadingTaskId,

    onClickBack,
    navigateToChallenges,
    onClickUploadArea,
    showHasDeleteDialog,
    toggleHasDeleteDialog,
  };
};
